@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("react-toastify/dist/ReactToastify.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
